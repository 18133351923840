import Button from '../../../components/Button';

const Hero = () => {
    return (
        <div class="flex z-10 relative flex-col items-center justify-center pt-20 gap-14 max-w-full min-h-screen" id="hero"> {/* Landing Section */}
          <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl bottom-40 left-0 bg-purple-700 w-64 h-64 md:w-72 md:h-72 lg:w-80 lg:h-80 rounded-6xl"/>
          <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl bottom-52 right-0 bg-fuchsia-700 w-64 h-64 md:w-72 md:h-72 lg:w-80 lg:h-80 rounded-full"/>
          <div class="flex flex-col items-center justify-center max-w-full">
            <h2 class='text-5xl text-center text-white font-extrabold leading-tight'>
              Your nightlife events<br/><span class="bg-gradient-to-r from-purple-400 from-[-20%] via-violet-500 via-40% to-violet-700 to-80% text-transparent bg-clip-text bg-clip-decoration">innovated</span>
            </h2>
          </div>
          <p class='text-lg md:text-xl max-w-md text-center text-white'>
              Sell Tickets & Tables in minutes through a modern platform designed for nightlife events. 
          </p>
          <a href="#contact">
            <Button className="px-16">
              Get Started
            </Button>
          </a>
          <p class='text-lg text-center text-center text-white'>
            {/* 💜<br/>Loved by 10,000 users */}
            Get started now, it's <u>free</u>!
          </p>
        </div>
    )
}

export default Hero;