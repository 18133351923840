export const navigation = [
    {
        id: "0",
        title: "Explore",
        url: "/",
        onlyMobile: false,
    },
    {
        id: "1",
        title: "For Organizers",
        url: "/organizers",
        onlyMobile: false
    },
    {
        id: "2",
        title: "Login",
        url: "/login",
        onlyMobile: true
    },
    {
        id: "3",
        title: "Sign Up",
        url: "/signup",
        onlyMobile: true
    },
    {
        id: "4",
        title: "Contact",
        url: "#contact",
        onlyMobile: false,
        disabled: true
    },
    
]