import { navigation } from "../constants"
import Button from "./Button"
import { useState } from "react"
import {enablePageScroll, disablePageScroll} from 'scroll-lock'
import { HamburgerMenu } from "./design/Header"
import MenuIcon from '@mui/icons-material/Menu';

import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"

export default function Navbar() {
    const { user } = useAuthContext();
    const { logout } = useLogout();

    const [openNavigation, setOpenNavigation] = useState(false)
    const toggleNavigation = () => {
        if (openNavigation) {
            setOpenNavigation(false);
            enablePageScroll();
        } else {
            setOpenNavigation(true);
            disablePageScroll();
        }
    };
    
    const handleClick = () => {
        if (!openNavigation) return;
        enablePageScroll();
        setOpenNavigation(false);
    };

    return (
        <div 
        // className={`fixed top-0 w-full h-20 bg-[#0a0118]/90 backdrop-blur-sm z-30
        // border-b border-[#252134]}`>
        className={`fixed top-0 h-20 w-full z-50 bg-[#0a0118]/90 backdrop-blur-sm border-b border-[#252134] ${
            openNavigation ? "bg-[#0a0118]" : "bg-[#0a0118]/90 backdrop-blur-sm"
          }`}
        >
            <div className="flex justify-center h-full items-center px-5 lg:px-7.5 xl:px-10 max-lg:py-4">
                <a className="block w-[12rem]" href="/">
                    <img class="max-h-8" src="partyapp.png"></img>
                </a>
                 <nav
                className={`${
                    openNavigation ? "flex items-center justify-center h-screen bg-[#0a0118]" : "hidden"
                } fixed top-[5rem] left-0 right-0 lg:static lg:flex lg:mx-auto lg:bg-transparent`}
                >
                    <div className={`relative z-2 flex flex-col items-center justify-center m-auto lg:flex-row ${openNavigation
                        ? "bottom-[5rem]" : ""}`}>
                        {navigation
                            .map((item) => (
                                <div>
                                    {!item.disabled && 
                                        <a key={item.id} 
                                        href={item.url} 
                                        className={`block relative px-6 py-8 lg:text-sm hover:text-violet-500 ${item.onlyMobile || 
                                        (user && user.isGuest && item.title == 'Login' || item.title == "Signup")  ? "lg:hidden" : ""}`}>
                                            {item.title}
                                        </a>
                                    }
                                    {item.disabled && 
                                        <p key={item.id} 
                                        href={item.url} 
                                        className={`text-zinc-400 block relative px-6 py-8 lg:text-sm ${item.onlyMobile && user && user.isGuest ? "lg:hidden" : ""}`}>
                                            {item.title}
                                        </p>
                                    }
                                </div>
                        ))}
                    </div>
                    <HamburgerMenu/>
                </nav>
                {user && user.isGuest &&
                    <div className="hidden lg:flex items-center">
                        <a href="/login" className="lg:text-sm px-6 hover:text-violet-500">Login</a>
                        <div className="px-6">
                            <a href="/signup">
                                <Button className="px-6 py-2 lg:text-sm">
                                    Sign Up
                                </Button>
                            </a>
                        </div>
                    </div>
                }

                {user && !user.isGuest &&
                    <div className="hidden lg:flex items-center px-8">
                        <button onClick={() => logout()}><p className="text-sm"><u>Logout</u></p></button>
                    </div>
                }   
                <button
                className="ml-auto lg:hidden"
                px="px-3"
                onClick={toggleNavigation}
                >
                   <MenuIcon color="white"/>
                </button>
            </div>
        </div>
    )
}