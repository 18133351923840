
const OrganizerCard = () => {
  return (
    <div
      className="w-48 h-28 bg-cover bg-center rounded-3xl"
      style={{ backgroundImage: 'url(/uno.png)' }}
    />
  )
}

export default OrganizerCard
