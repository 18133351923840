import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar'
import Events from './sections/Events';
import Hero from './sections/Hero';
import Organizers from './sections/Organizers';

function Explore() {
  return (
    <div className='min-h-screen'>
      <Navbar/>
      <div class="flex flex-col gap-10 w-screen max-w-7xl mx-auto min-h-screen px-5 pb-10 text-white App"> 
        <Hero/>
        <div class="flex flex-col gap-20 max-w-7xl pb-10 text-white"> 
          <Organizers/>
          <Events/>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default Explore;
