import OrganizerCard from "../../../components/OrganizerCard"

const Organizers = () => {
  return (
    <div>
      <div className="relative z-10" id="organizers">
            <div className="flex flex-col items-center justify-center gap-5 mb-10">
                <h1 className="text-3xl md:text-4xl z-10 font-extrabold text-center">
                    Partying in <span className="text-violet-600 to-80% text-transparent underline">Bucharest</span>
                </h1>
            </div>
      </div>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <h2 className="text-3xl">Organizers</h2>
          <div class="border-t border-gray-900"></div>
        </div>
        <OrganizerCard/>
      </div>

    </div>
  )
}

export default Organizers
