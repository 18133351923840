import ScanIcon from '@mui/icons-material/QrCodeScanner'
import TableSvg from '../../../assets/svgs/TableSvg.js'
import TicketSvg from '../../../assets/svgs/TicketSvg.js'
import GraphSvg from '../../../assets/svgs/GraphSvg.js'
import CustomerProfileSvg from '../../../assets/svgs/CustomerProfileSvg.js'
import StaffSvg from '../../../assets/svgs/StaffSvg.js'

const Services = () => {
  return (
    <div className="container mb-16" id="features">
        <div className="flex flex-col items-center justify-center gap-5 mb-16">
            <h1 class="text-4xl md:text-5xl z-10 font-extrabold text-center">
                Nightlife made <u>simple</u>.
            </h1>
            <h2 className="text-md z-10 text-[#ADA8C3] text-center">
                PartyApp reduces the time and effort of managing your nightlife events.
            </h2>
        </div>
        <div className="relative z-10 grid md:grid-cols-6 gap-2 mx-auto mb-5">
            <div className="relative z-10 md:col-span-3 flex items-center lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-auto">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -top-10 -left-10 bg-fuchsia-600 w-32 h-32 rounded-full"/>
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -bottom-10 -right-10 bg-purple-600 w-32 h-32 rounded-full"/>
                    <div className="mb-5 w-full" >
                        <TableSvg/>
                    </div>
                    <h4 className="h4 text-2xl md:text-3xl mb-4 font-semibold">Table Reservations</h4>
                    <p className="text-sm mb-4 text-[#ADA8C3]">
                       Sell tables in advance based on cash deposits or tickets.
                    </p>
                </div>
            </div>
            <div className="relative z-10 md:col-span-3 flex items-center lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-auto">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -bottom-90 -left-100 bg-red-400 w-32 h-32 rounded-full"/>
                    <div className="mb-5 w-full" >
                        <GraphSvg/>
                    </div>
                    <h4 className="h4 text-2xl md:text-3xl mb-4 font-semibold">Event Management</h4>
                    <p className="text-sm mb-4 text-[#ADA8C3]">
                       View real-time statuses of your reservations and easily manage your venue.
                    </p>
                </div>
            </div>
        </div>  
        <div className="relative z-10 grid md:grid-cols-2 gap-5 mx-auto mb-5">
            <div className="relative md:col-span-1 flex items-center lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-5">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -bottom-10 -left-50 bg-blue-700 w-48 h-28 rounded-full"/>
                    {/* <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -bottom-10 -right-10 bg-fuchsia-600 w-32 h-28 rounded-full"/> */}
                    <div className="mb-5 w-full" >
                        <ScanIcon fontSize='large'/>
                    </div>
                    <h4 className="h4 text-2xl md:text-3xl mb-4 font-semibold">Sell Tickets </h4>
                    <p className="text-sm text-[#ADA8C3]">Sell more tickets than ever before with our intuitive platform. No additional hardware needed for scanning.</p>
                </div>
            </div>
            <div className="relative z-10 md:col-span-1 flex items-center lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-5">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -top-10 -left-10 bg-green-400 w-32 h-32 rounded-full"/>
                    <h4 className="h4 text-2xl md:text-3xl mb-4 font-semibold">Fast Payments</h4>
                    <p className="text-sm mb-4 text-[#ADA8C3]">
                        The quickest and most frictionless way to take payments.
                    </p>
                    <div class="flex items-center gap-2 w-full flex-wrap">
                        <img class="h-8 rounded-lg p-1 bg-white" src="apple.png"/>
                        <img class="h-8 rounded-lg p-1 bg-white" src="google.png"/>
                        <img class="h-8 rounded-lg" src="link.jpeg"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="relative z-10 grid md:grid-cols-6 gap-5 mx-auto">
            <div className="relative md:col-span-3 flex items-center h-84 lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-auto">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl top-10 -left-50 bg-yellow-700 w-64 h-48 rounded-full"/>
                    <div className="mb-5 w-full" >
                        <CustomerProfileSvg/>
                    </div>
                    <h4 className="h4 text-2xl md:text-3xl font-semibold mb-4">Customer Profiles</h4>
                    <p className="text-sm text-[#ADA8C3]">
                        Know your customers better. Keep track of your highest-spending VIP clients and their service preferences.
                    </p>
                </div>
            </div>
            <div className="relative md:col-span-3 flex items-center h-84 lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-auto">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl bottom-10 right-0 bg-pink-700 w-64 h-48 rounded-full"/>
                    <div className="mb-5 w-full" >
                        <StaffSvg/>
                    </div>
                    <h4 className="h4 text-2xl md:text-3xl font-semibold mb-4">Staff Accounts</h4>
                    <p className="text-sm text-[#ADA8C3]">
                        Manage the roles of your staff and their permissions. Keep track of who is in charge of what.
                    </p>
                </div>
            </div>
        </div>     
    </div>
  )
}

export default Services
