import { Description, Field, Label, Select, Input } from '@headlessui/react'
import { KeyboardArrowDown } from '@mui/icons-material';
import Button from '../../../components/Button';
import { useState } from 'react';

const Contact = () => {
    const [eventName, setEventName] = useState('');
    const [location, setLocation] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [plan, setPlan] = useState('Essential');

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Process form data (e.g., send to a server or API)
        console.log({
            eventName,
            location,
            name,
            email,
            plan,
        });

        const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/organizer/partnership', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({eventName, location, name, email, plan})
        });
        const json = await response.json();

        if (!response.ok) {
            console.log(json.error)
        }
        
        if (response.ok) {
            alert("Thank you for your interest. We will reach out to you shortly.")
        }
    };


    return (
        <div className="container relative z-10" id="contact">
            <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl m-auto left-0 right-52 top-12 bg-purple-700 w-36 h-36 rounded-6xl"/>
          <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl m-auto left-52 right-0 bg-fuchsia-700 w-36 h-36 rounded-full"/>
            <div className="flex flex-col items-center justify-center gap-5 mb-10">
            <h1 class="text-4xl md:text-5xl z-10 font-extrabold text-center">
                Get Started
            </h1>
            <h2 className="text-md z-10 text-[#ADA8C3] text-center">
                We will reach out to you shortly.
            </h2>
            </div>
            <div className='flex justify-center w-full z-10'>
                <form onSubmit={handleSubmit} className='flex flex-col items-center p-12 border border-[#252134] rounded-3xl gap-5'>
                    <div className="w-full max-w-md px-4">
                        <Field>
                            <Label className="text-sm/6 font-medium text-white">Event / Club Name</Label>
                            <Input
                            value={eventName}
                            onChange={(e) => setEventName(e.target.value)}
                            className="
                                mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                "
                            />
                        </Field>
                    </div>
                    <div className="w-full max-w-md px-4">
                        <Field>
                            <Label className="text-sm/6 font-medium text-white">Country, City</Label>
                            <Input
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            className="
                                mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                "
                            />
                        </Field>
                    </div>
                    <div className="w-full max-w-md px-4">
                        <Field>
                            <Label className="text-sm/6 font-medium text-white">Name</Label>
                            <Description className="text-sm/6 text-white/50">Person of contact for the event</Description>
                            <Input
                            className="
                                mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                "
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            />
                        </Field>
                    </div>
                    <div className="w-full max-w-md px-4">
                        <Field>
                            <Label className="text-sm/6 font-medium text-white">Email</Label>
                            <Input
                            className="
                                mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                "
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                        </Field>
                    </div>
                    <div className="w-full max-w-md px-4">
                        <Field>
                            <Label className="text-sm/6 font-medium text-white">Plan</Label>
                            <Description className="text-sm/6 text-white/50">Please Select the plan you are interested in</Description>
                            <div className="relative flex items-center">
                                <Select
                                    className="
                                    mt-2 block w-full appearance-none rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                    focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                    *:text-black"
                                    value={plan}
                                    onChange={(e) => setPlan(e.target.value)}
                                >
                                    <option value="active">Essential</option>
                                    <option value="paused">Pro</option>
                                    <option value="delayed">Enterprise</option>
                                </Select>
                                <KeyboardArrowDown
                                    className="group pointer-events-none absolute top-3.5 right-2.5 size-4 text-white/60"
                                    aria-hidden="true"
                                />
                            </div>
                        </Field>
                    </div>
                    <Button className="px-16 py-2 text-sm" type="submit">
                        Submit
                    </Button>

                </form>
            </div>
        </div>  
    )
}

export default Contact;