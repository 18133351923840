import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Organizers from './pages/organizers/Organizers'
import Explore from './pages/explore/Explore'
import Login from './pages/Login';
import Signup from './pages/Signup';

import { useEffect } from 'react';
import { useState } from 'react';
import { useAuthContext } from './hooks/useAuthContext';
import { useLogout } from './hooks/useLogout';
import { useGuestSignup } from './hooks/useGuestSignup';

function App() {
  const { user } = useAuthContext();
  const [isNotLogged, setIsNotLogged] = useState(false);
  const { logout } = useLogout();
  const { guestSignup } = useGuestSignup();

  useEffect(() => {
    const f = async() => {
      if (user) {
          const email = user.email
          const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/user/guest', {
              method: 'GET',
              headers: {  
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
              }
          });
          const json = await response.json();
          if (!response.ok) {
              console.log(json.error)
          }
          if (response.ok) {
              user.isGuest = json.isGuest;
              localStorage.setItem('user', JSON.stringify(user));
          } else {
            logout()
          }
      } else if (!localStorage.getItem('user')) {
          await guestSignup();
      }
    }
    f();
  }, [user && user.isGuest])



  return (
    <div className='bg-dark-purple'>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Explore/>}
          />
          <Route
            path="/organizers"
            element={<Organizers/>}
          />
          <Route
            path="/login"
            element={!user || user.isGuest ? <Login/> : <Navigate to="/"/>}
          />
          <Route
            path="/signup"
            element={!user || user.isGuest ? <Signup/> : <Navigate to="/"/>}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
