import Navbar from '../../components/Navbar'
import Hero from './sections/Hero';
import Services from './sections/Services';
import Partners from './sections/Partners';
import Pricing from './sections/Pricing';
import Contact from './sections/Contact';
import Footer from '../../components/Footer';

function Organizers() {
  return (
    <div className='min-h-screen'>
      <Navbar/>
      <div class="flex flex-col gap-10 w-screen items-center justify-center max-w-7xl mx-auto min-h-screen px-5 md:px-10 pb-10 text-white App"> 
        <Hero/>
        {/* <Partners/> */}
        <Services/>
        <Pricing/>
        <Contact/>
      </div>
      <div class="flex flex-col gap-20 w-screen max-w-7xl mx-auto px-5 md:px-10 pb-10 text-white"> 
        <Footer/>
      </div>
    </div>
  );
}

export default Organizers;
