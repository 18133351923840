import ScanIcon from '@mui/icons-material/QrCodeScanner'
import TableSvg from '../../../assets/svgs/TableSvg.js'
import TicketSvg from '../../../assets/svgs/TicketSvg.js'
import GraphSvg from '../../../assets/svgs/GraphSvg.js'
import CustomerProfileSvg from '../../../assets/svgs/CustomerProfileSvg.js'
import StaffSvg from '../../../assets/svgs/StaffSvg.js'
import { CheckCircle } from '@mui/icons-material'
import Button from '../../../components/Button';

const Pricing = () => {
  return (
    <div className="container" id="pricing">
        <div className="flex flex-col items-center justify-center gap-5 mb-10">
            <h1 class="text-4xl md:text-5xl z-10 font-extrabold text-center">
                Pricing
            </h1>
            <h2 className="text-md z-10 text-[#ADA8C3] text-center">
                Take your event to the next level.
            </h2>
        </div>
        <div className="relative z-10 grid md:grid-cols-6 gap-5 mx-auto mb-5">
            <div className="relative flex flex-col z-10 md:col-span-2  items-center lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-auto">
                    {/* <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -top-10 -left-10 bg-fuchsia-600 w-32 h-32 rounded-full"/> */}
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -top-0 -left-5 bg-yellow-500 w-36 h-36 rounded-full"/>
                    <h3 className="h4 bg-yellow-500 text-transparent bg-clip-text bg-clip-decoration text-2xl md:text-3xl mb-4 font-semibold">Essential</h3>
                    <div className="mb-6">
                        <h4 className="h4 text-4xl md:text-5xl font-semibold">$0</h4>
                    </div>
                    <Button className="px-16 py-2 mb-6 text-sm
                    shadow-yellow-600 bg-gradient-to-r from-yellow-500 via-yellow-600 
                    via-40% to-yellow-500 bg-size-100 bg-pos-95 hover:bg-pos-0 hover:shadow-4xl hover:shadow-yellow-500
                    ">
                        Get started
                    </Button>
                    <div className='flex flex-col gap-4 mb-6'>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-yellow-500"/>
                            <p className="text-md text-white">Sell Tickets & Tables on PartyApp</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-yellow-500"/>
                            <p className="text-md text-white">Scanning Software</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-yellow-500"/>
                            <p className="text-md text-white">Event Management Dashboard</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-yellow-500"/>
                            <p className="text-md text-white">Customer Profiles</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-yellow-500"/>
                            <p className="text-md text-white">Unlimited Staff Accounts</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col z-10 md:col-span-2 items-center lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-auto">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -bottom-90 left-10 bg-purple-500 w-32 h-32 rounded-full"/>
                    <h4 className="h4 bg-purple-500 text-transparent bg-clip-text bg-clip-decoration text-2xl md:text-3xl mb-4 font-semibold">Pro</h4>
                    <div className="mb-6 flex items-end gap-1">
                        <h4 className="h4 text-4xl md:text-5xl font-semibold">$30</h4>
                        <h4 className="h4 text-xl md:text-xl font-semibold">/month</h4>
                    </div>
                    <Button className="px-16 py-2 mb-6 text-sm">
                        Get started
                    </Button>
                    <div className='flex flex-col gap-4 mb-6'>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-purple-500"/>
                            <p className="text-md text-white">Everything included in Essential</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-purple-500"/>
                            <p className="text-md text-white">PartyApp integration on your website</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-purple-500"/>
                            <p className="text-md text-white">Dedicated Support</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative flex flex-col z-10 md:col-span-2  items-center lg:mb-5 p-8 border border-[#252134] rounded-3xl overflow-hidden lg:p-10">
                <div className="relative z-10 lg:mx-auto">
                    <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl -bottom-90 -right-10 bg-rose-500 w-32 h-32 rounded-full"/>
                    <h4 className="h4 bg-rose-500 text-transparent bg-clip-text bg-clip-decoration text-2xl md:text-3xl mb-4 font-semibold">Enterprise</h4>
                    <div className="mb-6 flex items-end gap-1">
                        <h4 className="h4 text-4xl md:text-5xl font-semibold">On request</h4>
                    </div>
                    <Button className="px-16 py-2 mb-6 text-sm
                    shadow-red-600 bg-gradient-to-r from-red-500 via-red-600 
                    via-40% to-red-500 bg-size-100 bg-pos-95 hover:bg-pos-0 hover:shadow-4xl hover:shadow-red-500
                    ">
                        Get started
                    </Button>
                    <div className='flex flex-col gap-4 mb-6'>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-rose-500"/>
                            <p className="text-md text-white">Everything included in Pro</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-rose-500"/>
                            <p className="text-md text-white">Custom Website personalized for your brand image </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-rose-500"/>
                            <p className="text-md text-white">Custom software requirements for your events</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CheckCircle className="text-rose-500"/>
                            <p className="text-md text-white">Sell unique services to your clients</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>       
    </div>
  )
}

export default Pricing
