import { Field, Input, Label } from "@headlessui/react"
import Button from "./Button"
const Footer = () => {
  return (
    <div>
        <div class="border-t border-gray-900 my-5"></div>
        <div className="flex flex-col md:flex-row gap-8 md:justify-around md:flex-wrap pt-5 pb-5">
            <div className="flex flex-col items gap-3">
                <img src="partyapp.png" className="w-32"/>
                <p className="text-sm">The Future of Partying</p>
                <div className="flex flex-col gap-1">
                    <p className="text-sm font-thin text-gray-400">© Copyright 2024</p>
                    <p className="text-sm font-thin text-gray-400">PartyApp Technologies</p>
                    <p className="text-sm font-thin text-gray-400">All rights reserved.</p>
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <p className="text-md">Product</p>
                <a href="/">
                    <p className="text-md font-thin text-gray-400">Explore</p>
                </a>
                <a href="/organizers">
                    <p className="text-md font-thin text-gray-400">Publish Events</p>
                </a>
            </div>
            <div className="flex flex-col gap-3">
                <p className="text-md">Legal</p>
                <a href="terms.pdf">
                    <p className="text-md font-thin text-gray-400">Terms of Service</p>
                </a>
                <a href="privacy.pdf">
                    <p className="text-md font-thin text-gray-400">Privacy Policy</p>
                </a>
            </div>
            <div className="flex flex-col gap-1">
                <p className="text-md">Subscribe to our newsletter</p>
                <p className="text-sm font-thin text-gray-400">Get notified about parties exclusive events, and more.</p>
                <div className="flex gap-2 items-center">
                        <Field>
                            <Input
                            className="
                                block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                h-11
                                "
                            />
                        </Field>
                        <Button className="px-7 text-sm h-11">Subscribe</Button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
