import Button from '../../../components/Button';

const Hero = () => {
    return (
        <div class="flex z-10 relative flex-col items-center justify-center pt-20 gap-14 max-w-full min-h-screen" id="hero"> {/* Landing Section */}
          {/* <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl top-40 -left-24 bg-purple-700 w-64 h-52 md:w-72 md:h-72 lg:w-80 lg:h-80 rounded-6xl"/> */}
          {/* <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-3xl bottom-36 right-0 lg:-right-32 bg-fuchsia-700 w-52 h-64 md:w-72 md:h-72 lg:w-80 lg:h-80 rounded-full"/>
          <div class="absolute mix-blend-multiply brightness-50 -z-10 blur-2xl top-40 left-1/2 bg-white w-24 h-24 rounded-6xl"/> */}
          <div class="flex flex-col items-center justify-center max-w-full">
            <h2 class='text-5xl text-center text-white font-extrabold leading-tight'>
              The best parties,<br/>in one <span class="bg-gradient-to-r from-purple-400 from-[-20%] via-violet-500 via-40% to-violet-700 to-80% text-transparent bg-clip-text bg-clip-decoration">place</span>
            </h2>
          </div>
          <a href="#events">
            <Button className="px-16">
              Explore
            </Button>
          </a>
          <p class='text-lg text-center text-center text-white'>
            💜<br/>Loved by 10,000 users
          </p>
        </div>
    )
}

export default Hero;