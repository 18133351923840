import { Description, Field, Label, Select, Input } from '@headlessui/react'
import { KeyboardArrowDown } from '@mui/icons-material';
import Button from '../components/Button';
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import { useLogin } from "../hooks/useLogin" 
import { useState } from 'react';
import Loading from '../components/Loading';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {login, error, isLoading} = useLogin();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await login(email, password);
    }

    return (
      <div className="min-h-screen">
        <Navbar/>
        {isLoading &&
        <Loading/>
        }
        {!isLoading &&
        <div className="flex flex-col gap-10 w-screen max-w-7xl mx-auto min-h-screen px-5 pb-10 text-white App">
            <div className="flex flex-col items-center justify-center p-7 min-h-screen">
                {/* <div className='flex justify-center w-full z-10 gap-5'> */}
                    <form className='flex flex-col items-center justify-center p-8 gap-8 w-full max-w-sm'>
                        <a className="block" href="/">
                            <img className="max-h-8" src="partyapp.png"></img>
                        </a>
                        <div className="w-full">
                            <Field>
                                <Label className="text-sm/6 font-medium text-white">Email</Label>
                                <Input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="
                                    mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                    focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                    "
                                />
                            </Field>
                        </div>
                        <div className="w-full">
                            <Field>
                                <Label className="text-sm/6 font-medium text-white">Password</Label>
                                <Input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="
                                    mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                    focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                    "
                                type="password"
                                />
                            </Field>
                        </div>
                        <Button onClick={(e) => handleSubmit(e)} className="px-16 py-2 text-sm w-full">
                            Submit
                        </Button>
                        <div className='flex flex-col gap-2 text-center'>
                            <p className='text-xs/6'>Don't have an account yet? <a className='underline' href="/signup">Sign Up</a></p>
                            <a className='underline text-xs/6' href='/forgot-password'>Forgot your password?</a>
                        </div>
                    </form>
                {/* </div> */}
            </div>
            <Footer/> 
        </div>
        }
      </div>
    )
  }
  
  export default Login
  