import PartyCard from "../../../components/PartyCard"

const Events = () => {
    return (
        <div id="events">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <h2 className="text-3xl">Past Events</h2>
              <div class="border-t border-gray-900"></div>
            </div>
            <div className="flex gap-3 flex-wrap">
              <PartyCard eventName="EUPHORIA" date="15.06.2024" imgUrl="euphoria.jpeg" location="TUYA" artists="Eva Kim, Ozlemek" hour="21:00"/>
              <PartyCard eventName="RAYANA" date="19.04.2024" imgUrl="rayana.jpg" location="NAYAM" artists="Fiorella, Big Pizza, Ozlemek" hour="22:00"/>
              <PartyCard eventName="SECRETÉ" date="05.04.2024" imgUrl="secret.jpg" location="Nuba White" artists="Arymé, Big Pizza" hour="22:00"/>
              <PartyCard eventName="ZENITH" date="23.02.2024" imgUrl="zenith.png" location="Hiro Bay" artists="Maxi Meraki, Ozlemek" hour="21:00"/>
              <PartyCard eventName="LUMIÈRE" date="26.01.2024" imgUrl="lumiere.jpeg" location="Nuba" artists="Mont Rouge, Ozlemek" hour="21:00"/>
            </div>
          </div>
    
        </div>
    )
}

export default Events
