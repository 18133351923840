import { Description, Field, Label, Select, Input, Radio, RadioGroup } from '@headlessui/react'
import { KeyboardArrowDown } from '@mui/icons-material';
import Button from '../components/Button';
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import { useState } from 'react';
import { useSignup } from "../hooks/useSignup"
import { useAuthContext } from '../hooks/useAuthContext';
import Loading from '../components/Loading';


const Signup = () => {
    const { user } = useAuthContext();
    const {signup, error, isLoading} = useSignup();


    const generateYears = (startYear, endYear) => {
        const years = [];
        for (let i = endYear; i >= startYear; i--) {
            years.push({ value: i, label: i });
        }
        return years;
    };

    const generateMonths = () => {
        const months = [
            { value: 1, label: 'January' },
            { value: 2, label: 'February' },
            { value: 3, label: 'March' },
            { value: 4, label: 'April' },
            { value: 5, label: 'May' },
            { value: 6, label: 'June' },
            { value: 7, label: 'July' },
            { value: 8, label: 'August' },
            { value: 9, label: 'September' },
            { value: 10, label: 'October' },
            { value: 11, label: 'November' },
            { value: 12, label: 'December' },
        ];
        return months;
    };

    const generateDays = (year, month) => {
        const days = [];
        const date = new Date(year, month, 0);
        const numberOfDays = date.getDate();
        for (let i = 1; i <= numberOfDays; i++) {
            days.push({ value: i, label: i });
        }
        return days;
    };

    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');

    const handleYearChange = (event) => {
        setYear(event.target.value);
        validateDay(event.target.value, month);
    };

    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        validateDay(year, event.target.value);
    };

    const handleDayChange = (event) => {
        setDay(event.target.value);
    };

    const validateDay = (year, month) => {
        const numberOfDays = new Date(year, month, 0).getDate();
        if (day > numberOfDays) {
            setDay('');
        }
    };

    const parseDate = (day, month, year) => {
        return year + '-' + month + '-' + day
    }

    const handleSubmit = async (e) => {
        const birthdate = parseDate(day, month, year)
        e.preventDefault();
        await signup(email, password, first_name, last_name, gender, birthdate, user.email)
    }

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [gender, setGender] = useState(null)


    return (
        <div className="min-h-screen">
            <Navbar />
            { isLoading &&
                <Loading/>
            }
            { !isLoading &&
            <div className="flex flex-col gap-10 w-screen max-w-7xl mx-auto min-h-screen px-5 pb-10 pt-10 text-white App">
                <div className="flex flex-col items-center justify-center p-0 min-h-screen">
                    {/* <div className='flex justify-center w-full z-10 gap-5'> */}
                    <form className='flex flex-col items-center justify-center py-8 gap-8 w-full max-w-sm'>
                        <div className="w-full">
                            <Field>
                                <Label className="text-sm/6 font-medium text-white">First Name</Label>
                                <Input
                                    className="
                                        mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                        focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                    "
                                    value={first_name}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </Field>
                        </div>
                        <div className="w-full">
                            <Field>
                                <Label className="text-sm/6 font-medium text-white">Last Name</Label>
                                <Input
                                    className="
                                        mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                        focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                    "
                                    value={last_name}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </Field>
                        </div>
                        <div className="w-full">
                            <Field>
                                <Label className="text-sm/6 font-medium text-white">Birthdate</Label>
                                <div className='flex gap-1'>
                                    <Select
                                        value={day}
                                        onChange={handleDayChange}
                                        className="
                                            mt-2 block w-4/12 rounded-lg border-none bg-white/5 py-3 px-3 text-sm/6 text-white
                                            focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                        "
                                    >
                                        <option value="">Day</option>
                                        {generateDays(year, month).map((day) => (
                                            <option key={day.value} value={day.value}>{day.label}</option>
                                        ))}
                                    </Select>
                                    <Select
                                        value={month}
                                        onChange={handleMonthChange}
                                        className="
                                            mt-2 block w-4/12 rounded-lg border-none bg-white/5 py-3 px-3 text-sm/6 text-white
                                            focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                        "
                                    >
                                        <option value="">Month</option>
                                        {generateMonths().map((month) => (
                                            <option key={month.value} value={month.value}>{month.label}</option>
                                        ))}
                                    </Select>
                                    <Select
                                        value={year}
                                        onChange={handleYearChange}
                                        className="
                                            mt-2 block w-4/12 rounded-lg border-none bg-white/5 py-3 px-3 text-sm/6 text-white
                                            focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                        "
                                    >
                                        <option value="">Year</option>
                                        {generateYears(1950, new Date().getFullYear()).map((year) => (
                                            <option key={year.value} value={year.value}>{year.label}</option>
                                        ))}
                                    </Select>
                                </div>
                            </Field>
                        </div>

                        <div className="w-full">
                            <Field>
                                <Label className="text-sm/6 font-medium text-white">Email</Label>
                                <Input
                                    className="
                                        mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                        focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                    "
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Field>
                        </div>
                        <div className="w-full">
                            <Field>
                                <Label className="text-sm/6 font-medium text-white">Password</Label>
                                <Input
                                    className="
                                        mt-2 block w-full rounded-lg border-none bg-white/5 py-1.5 px-3 text-sm/6 text-white
                                        focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                    "
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Field>
                        </div>
                        <div className="w-full">
                            <RadioGroup
                                value={gender}
                                onChange={setGender}
                                className="
                                    flex gap-5
                                    mt-2 w-full rounded-lg border-none py-1.5 text-sm/6 text-white
                                    focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25
                                "
                            >
                                <Field className="flex items-center gap-2">
                                    <Radio value="female" className="group flex size-5 items-center justify-center rounded-full bg-white data-[checked]:bg-purple-800">
                                    </Radio>
                                    <Label>Female</Label>
                                </Field>
                                <Field className="flex items-center gap-2">
                                    <Radio value="male" className="group flex size-5 items-center justify-center rounded-full bg-white data-[checked]:bg-purple-800">
                                    </Radio>
                                    <Label>Male</Label>
                                </Field>
                            </RadioGroup>
                        </div>
                        <Button onClick={(e) => handleSubmit(e)} className="px-16 py-2 text-sm w-full">
                            Submit
                        </Button>
                        <p className='text-xs/6'>Already have an account? <a className='underline' href="/login">Log In</a></p>
                    </form>
                    {/* </div> */}
                </div>
                <Footer />
            </div>
            }
        </div>
    )
}

export default Signup
