import { useEffect } from "react"

const Button = ({ className, href, onClick, children }) => {
    const classes = `text-lg shadow-3xl transition-all duration-500 shadow-purple-600 bg-gradient-to-r from-violet-700 via-purple-600 
    via-40% to-violet-700 bg-size-100 bg-pos-95 hover:bg-pos-0 hover:shadow-4xl hover:shadow-purple-600 py-3 rounded-lg
    ${className || ""}`
    
    const renderButton = () => {
        return (
            <button className={classes} onClick={onClick}>
                {children}
            </button> 
        )
    }
    return renderButton();
}

export default Button
